import { Injectable } from '@angular/core';
import { InMemoryDbService } from 'angular-in-memory-web-api';

@Injectable({
	providedIn: 'root',
})
export class InMemoryDataService implements InMemoryDbService {
	createDb() {
		const pages = [
			{
				id: 1,
				name: 'intro',
				tagline: '',
				title: 'Services offered',
				description:
					'At Dr. Anushruti Aggarwal\'s Dermatology Clinic, we offer a comprehensive range of services to address various skin, hair, nail, and venereological conditions. As a dermatosurgeon and aesthetic physician, Dr. Aggarwal specializes in the following treatments: ',
			},
			{
				id: 2,
				name: 'clients',
				tagline: 'TRUST',
				title: 'Companies who use our services',
				description:
					'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam!',
			},
			{
				id: 3,
				name: 'services',
				tagline: '',
				title: 'About Dr. Anushruti Aggarwal',
				description:
					'',
			},
			{
				id: 4,
				name: 'testimonials',
				tagline: 'FEEDBACK',
				title: 'What our customers are saying',
				description: '',
			},
			{
				id: 5,
				name: 'pricing',
				tagline: '',
				title: 'Book an appointment today!',
				description: '',
			},
			{
				id: 6,
				name: 'footer',
				tagline: 'Dr. Anushruti Aggarwal',
				developer: '',
				developerlink: '',
			},
			{
				id: 7,
				name: 'header',
				tagline: 'Welcome to Dr. Anushruti Aggarwal\'s Dermatology Clinic ',
				title:
					'As a board-certified dermatologist with extensive experience and a commitment to excellence, Dr. Anushruti Aggarwal welcomes you to her state-of-the-art dermatology clinic. With a passion for skin health and a dedication to patient care, Dr. Aggarwal offers a comprehensive range of services to address all your dermatological needs. Throughout her career, a commitment to staying at the forefront of dermatological advancements has been paramount. Actively engaged in continuing education, Dr. Aggarwal remains updated with the latest technologies and techniques, ensuring that patients receive the most innovative and effective treatments available. In her practice, patient-centric care is paramount. With a focus on holistic wellness and individualized treatment plans, Dr. Aggarwal strives to create a comfortable and supportive environment where patients feel empowered and informed throughout their skincare journey. By blending medical expertise with empathy and compassion, she fosters strong patient relationships built on trust and mutual respect. ',
				buttontext: 'Read More',
				buttonlink: '/about',
				image: 'banner-image-1.jpg',
			},
		];
		const features = [
			{
				id: 1,
				icon: 'chevron-down',
				title: 'Hair Fall Treatment',
				description:
					'Combat hair loss and promote hair regrowth with our advanced treatment options.',
				image: 'hairfall.jpg',
			},
			{
				id: 2,
				icon: 'chevron-down',
				title: 'Skin Brightening Treatments',
				description:
					'Achieve a radiant complexion with our customized skin brightening treatments tailored to your unique needs',
				image: 'skin-brightening.jpg',
			},
			{
				id: 3,
				icon: 'chevron-down',
				title: 'Acne Scar Treatment',
				description:
					'Say goodbye to acne scars and achieve smoother, clearer skin with our cutting-edge treatment',
				image: 'acne-scar.jpg',
			},
			{
				id: 4,
				icon: 'chevron-down',
				title: 'Chemical Peels',
				description:
					'Renew and rejuvenate your skin with our range of chemical peel treatments designed to address various skin concerns',
				image: 'chemical-peels.jpg',
			},
			{
				id: 5,
				icon: 'chevron-down',
				title: 'Ingrown Toenail Treatment',
				description:
					'Find relief from ingrown toenails with our expert treatment solutions. ',
				image: 'ingrown-toenail.jpg',
			},
			{
				id: 5,
				icon: 'chevron-down',
				title: 'Microneedling',
				description:
					'Say goodbye to fine lines, wrinkles, age spots and acne scars. ',
				image: 'microneedling.jpg',
			},
			{
				id: 5,
				icon: 'chevron-down',
				title: 'Hydrafacial Treatment',
				description:
					'Experience the ultimate glow-up and rejuvenate your skin. ',
				image: 'hydrafacial-treatment.jpeg',
			},
			{
				id: 5,
				icon: 'chevron-down',
				title: 'PRP Therapy',
				description:
					'Say goodbye to hair loss with PRP treatment. ',
				image: 'prp-therapy.jpg',
			},
		];
		const images = [
			{ id: 1, name: 'gallery-image-1.jpg' },
			{ id: 2, name: 'gallery-image-2.jpg' },
			{ id: 3, name: 'gallery-image-3.jpg' },
			{ id: 4, name: 'gallery-image-4.jpg' },
			{ id: 5, name: 'gallery-image-5.jpg' },
			{ id: 6, name: 'gallery-image-6.jpg' },
		];
		const menu = [
			{ id: 1, title: 'Home', link: '/home' },
			{ id: 2, title: 'About', link: '/about' },
			{ id: 3, title: 'Services', link: '/services' },
			// { id: 4, title: 'Gallery', link: '/gallery' },
			// { id: 5, title: 'Testimonials', link: '/testimonials' },
			// { id: 6, title: 'Clients', link: '/clients' },
			{ id: 7, title: 'Book An appointment', link: '/contact' },
		];
		const companies = [
			{
				id: 1,
				name: 'Tree',
				weblink: 'company-logo1.png',
				logo: 'company-logo1.png',
			},
			{
				id: 2,
				name: 'Fingerprint',
				weblink: 'company-logo2.png',
				logo: 'company-logo2.png',
			},
			{
				id: 3,
				name: 'The Man',
				weblink: 'company-logo3.png',
				logo: 'company-logo3.png',
			},
			{
				id: 4,
				name: 'Mustache',
				weblink: 'company-logo4.png',
				logo: 'company-logo4.png',
			},
			{
				id: 5,
				name: 'Goat',
				weblink: 'company-logo5.png',
				logo: 'company-logo5.png',
			},
			{
				id: 6,
				name: 'Justice',
				weblink: 'company-logo6.png',
				logo: 'company-logo6.png',
			},
			{
				id: 7,
				name: 'Ball',
				weblink: 'company-logo7.png',
				logo: 'company-logo7.png',
			},
			{
				id: 8,
				name: 'Cold',
				weblink: 'company-logo8.png',
				logo: 'company-logo8.png',
			},
			{
				id: 9,
				name: 'Cold',
				weblink: 'company-logo9.png',
				logo: 'company-logo9.png',
			},
		];
		const feedback = [
			{
				id: 1,
				name: 'John Doe',
				userimage: 'user-1.jpg',
				comments:
					'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua',
				company: 'ABC',
			},
			{
				id: 2,
				name: 'Roslyn Doe',
				userimage: 'user-2.jpg',
				comments:
					'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua',
				company: 'Happy Customer',
			},
			{
				id: 3,
				name: 'Thomas Doe',
				userimage: 'user-3.jpg',
				comments:
					'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua',
				company: 'Happy Customer',
			},
		];
		const plans = [
			{
				id: 1,
				title: 'Book an appointment',
				subtitle: '',
				description: 'Send an email or call on the number below',
				price: '',
				currency: '',
				downloads: 'Email: dranushrutiderma@gmail.com',
				extensions: 'Phone: 8708658318',
				tutorials: '',
				support: '',
				updates: '',
				buttontext: 'BUY TODAY',
				buttonlink: '#',
				featured: false,
			},
			
		];
		const websites = [
			// {
			// 	id: 1,
			// 	link: 'https://facebook.com/',
			// 	title: 'Facebook',
			// 	target: '_blank',
			// 	username: 'Thor',
			// 	icon: 'facebook',
			// },
			// {
			//   id: 2,
			//   link: "https://googleplus.com/",
			//   title: "Google+",
			//   target: "_blank",
			//   username: "+jagmohan",
			//   icon: "google-plus",
			// },
			{
				id: 3,
				link: 'https://youtube.com/',
				title: 'Youtube',
				target: '_blank',
				username: '@dranushrutiaggarwal',
				icon: 'youtube',
			},
			{
				id: 4,
				link: 'https://instagram.com/',
				title: 'Instagram',
				target: '_blank',
				username: 'dranushrutiaggarwal',
				icon: 'instagram',
			},
			// {
			//   id: 5,
			//   link: "https://behance.com/",
			//   title: "Behance",
			//   target: "_blank",
			//   username: "jagmohan",
			//   icon: "behance",
			// },
		];

		return {
			menu,
			pages,
			features,
			images,
			companies,
			feedback,
			plans,
			websites,
		};
	}
}
